import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/MainLayout"),
      children: [
        {
          path: "",
          component: () => import("@/views/Home")
        },
        {
          path: "about",
          component: () => import("@/views/About")
        },
        {
          path: "drivers",
          component: () => import("@/views/Drivers")
        },
        {
          path: "deleteAccount",
          component: () => import("@/views/DeleteAccount")
        },
        {
          path: "advertising",
          component: () => import("@/views/Advertising")
        },
      ]
    },
    {
      path: '/:catchAll(.*)*',
      redirect: to => {
        return { path: '/' }
      },
    }
  ]
});