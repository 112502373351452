export default (app, Vue) => {
  Vue.prototype.$initLanguaje = () => {
    let lang = localStorage.getItem('lang')
    if (lang === '' || lang === undefined || lang === null) {
        var deviceLang = navigator.language || navigator.userLanguage;
        lang = deviceLang.split('-')[0];
    }
    app.i18n.locale = lang
    // eliminar la siguiente lines, solo es para dejar fijo el idioma en español
    app.i18n.locale = 'es'
  }

  Vue.prototype.$setDefaultHTTPLanguajeCode = () => {
    localStorage.setItem('lang', app.i18n.locale)
  }
}
