import Vue from 'vue';
import VueI18n from 'vue-i18n'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import App from './App.vue';
import router from './router';

import messages from '@/i18n'
import ApiService from "./common/api.service";
import helpers from '@/boot/helpers'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

window.$ = window.jQuery = require('jquery');
Vue.config.productionTip = false;
ApiService.init();

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueAwesomeSwiper)
Vue.use(VueI18n)

// Set i18n instance on app
App.i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
})

helpers(App, Vue);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
